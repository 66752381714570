<script lang="ts" setup>
import type { Game, GameLastWinner } from "@/types";

const props = defineProps<{
	game: Game;
	winnerData?: GameLastWinner;
	skeleton?: boolean;
	isRacesPopup?: boolean;
	isQuestPopup?: boolean;
	isSearchPopup?: boolean;
	hideFavorite?: boolean;
}>();
const emit = defineEmits<{ (event: "toggleFavorite", game: Game): void; (event: "close"): void }>();

const route = useRoute();
const { isMobile } = useDevice();
const { open, close } = useFunrizeModals();
const { data: appInit, favoritesSet } = useAppInitData();
const { handleOpenGame } = useOpenGame(open);
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const isGuest = useIsGuest();
const { isSweepStakes } = useSwitchMode();

const alt = computed(() => removeHtmlTags(props.game.title || ""));
const exclusiveLock = computed(() => props.game.isExclusive && !isSweepStakes.value);

const handleToggleToFavorite = () => {
	emit("toggleFavorite", { ...props.game, isFavorite: favoritesSet.value.has(props.game.id) });
};

const handleOverlayClick = () => {
	if (props.game.isOnlyForApp) {
		open("LazyOModalGetAppMain");
		return;
	}

	if (exclusiveLock.value) {
		open("LazyOModalGamePreviewExclusive", {
			gameId: props.game.id,
			winnerData: props.winnerData
		});
		return;
	}

	if (props.game.gamePlayInfo && !props.isRacesPopup && !props.isQuestPopup && !props.isSearchPopup) {
		open("LazyOModalGamePreview", {
			game: props.game,
			winnerData: props.winnerData
		});
		dispatchGAEvent({
			event: "click_button",
			location: props.game.slug ?? "",
			button_name: "gameplay",
			form_name: (route.params.pageName as string) ?? ""
		});
		return;
	}

	if (props.isRacesPopup) {
		close("LazyOModalRacesGames");
	}

	if (props.isQuestPopup) {
		close("LazyOModalQuestGames");
	}

	if (props.isSearchPopup) {
		close("LazyOModalGameSearch");
	}

	if (!isGuest.value) {
		dispatchGAEvent({
			event: "click_button",
			step: "start",
			type: appInit.value?.gameMode === "SweepStakes" ? "entries" : "coins"
		});
	}

	emit("close");
	handleOpenGame(props.game.slug, "gameplay");
};
</script>

<template>
	<div :class="{ 'card-wrapper': true, 'has-skeleton': skeleton }">
		<AAnimationSkeleton v-if="skeleton" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>
		<ACard
			v-else
			:img-attrs="{
				width: 160,
				height: 200,
				src: `${baseImageUrl}${game?.logo || game?.img || '/nuxt-img/card-games/pattern.webp'}`,
				format: 'webp',
				alt,
				loading: 'lazy'
			}"
			height="auto"
			stretch="125%"
			variant="game"
			width="auto"
			:data-tid="`gcard-${game.id}`"
			@click="handleOverlayClick"
		>
			<MGameActivityFeedBadge v-if="!isGuest" :winner="winnerData" />
			<div v-if="(game.isNew || game.isFishing || game.isExclusive) && !game.isOnlyForApp" class="badge-wrap">
				<MGameBadge v-if="game.isExclusive" variant="exclusive" />
				<MGameBadge v-if="game.isFishing" variant="fishing" />
				<MGameBadge v-if="game.isNew" variant="new" />
			</div>
			<AText :modifiers="['center', 'bold', 'uppercase']" as="div" class="text-cannes" variant="tanta">
				<span class="title" v-html="game.title" />
			</AText>
			<div v-if="game.isOnlyForApp || exclusiveLock" class="lock-overlay">
				<AOverlay :modifiers="['blur', 'flex-center']" bgColor="var(--chandigarh)">
					<NuxtIcon filled name="36/locked" />
				</AOverlay>
			</div>
			<AAnimationFadeIn v-else>
				<AOverlay v-if="!isMobile" bgColor="var(--cambe)">
					<div class="game-content">
						<div v-if="!isGuest && !hideFavorite" class="center-block">
							<AButton
								:id="`btn-fav-${game.id}`"
								aria-label="Favorite"
								:data-tid="`fav-${game.id}`"
								:modifiers="favoritesSet.has(game.id) ? ['active'] : undefined"
								variant="toolbar"
								@click.stop="handleToggleToFavorite"
							>
								<NuxtIcon class="fav" filled name="16/fav" />
							</AButton>
						</div>
						<div class="center-block">
							<AButton
								:id="`btn-play-${game.id}`"
								aria-label="Play"
								:data-tid="`gplay-${game.id}`"
								:modifiers="['circle']"
								variant="ghost"
							>
								<NuxtIcon filled name="54/play" />
							</AButton>
						</div>
					</div>
				</AOverlay>
			</AAnimationFadeIn>
		</ACard>
	</div>
</template>

<style lang="scss" scoped>
.has-skeleton {
	position: relative;

	--a-skeleton-background-color: var(--a-button-navigation-border-color);
}
.skeleton-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.fav {
	line-height: 1;
	margin-top: -2px;
}

.badge-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: gutter(0.5);
	position: absolute;
	top: 0;
	left: -2px;
	z-index: 1;

	&:deep(.badge.skew) {
		padding: 0 gutter(1.5);
	}
}

.lock-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 64px;
	height: 64px;
	position: absolute;
	border-radius: 10px;
	overflow: hidden;
	font-size: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	@include media-breakpoint-down(md) {
		width: 40px;
		height: 40px;
		font-size: 22px;
		border-radius: 8px;
	}
}

.title {
	width: calc(100% - 24px);
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: gutter(1);
	left: 50%;
	transform: translate(-50%, 0);
	gap: gutter(0.5);
	z-index: 1;
	letter-spacing: 0.04em;
	line-height: 1;
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

	&:deep(> span) {
		font-size: 12px;
		font-weight: 400;
		text-shadow: none;
	}
}

.card-wrapper {
	&:hover .card > div {
		opacity: 1;
	}
}

.game-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: gutter(2);
	z-index: 1;

	&:hover {
		.center-block button.ghost {
			transform: translate(-50%, -50%) scale(1.1);

			&:deep(.nuxt-icon) {
				transform: translateX(2px) scale(1.1);
			}
		}
	}

	.center-block {
		position: absolute;
		display: flex;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		right: 0;

		button.ghost {
			transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 56px;
			padding: 4px 8px;

			&:deep(.nuxt-icon) {
				transition: all 0.3s ease-in;
			}
		}
	}
}
</style>
